:root {
  --txt: #1f1d1d;
  --txt-hover: #4d4848;
  --bg1: #f5f5f5;
  --bg2: #ffffff;
  --border: hsl(0, 0%, 90%);
  --border-vert: hsl(0, 0%, 85%);
  --wilsweblue: #23b0ff;
}

body {
  background-color: var(--bg2);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  color: var(--txt);
  overflow-x: hidden;
}

.site-head {
  background-color: var(--bg1);
}

.fullscreen {
  height: 100vh;
}

.pad-head {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.d-none {
  display: none;
}

nav#links {
  display: flex;
  flex-direction: row;
}

nav#links svg {
  fill: var(--txt);
  width: 64px;
  height: 64px;
}

nav#links svg:hover {
  fill: var(--txt-hover);
}

#links a {
  display: block;
  color: var(--txt);
  text-align: center;
  padding: 0.5rem 0.7rem;
  text-decoration: none;
}

a {
  color: var(--txt);
}

a:visited {
  color: var(--txt);
}

a:hover {
  color: var(--txt-hover);
}

.projects a {
  text-align: left;
}

.divider-vert::before {
  border-left: 0.1rem solid var(--border-vert);
}

.card {
  border: 0.05rem solid var(--border);
}

.form-input {
  border: 0.05rem solid var(--border-vert) !important;
  background: inherit;
  color: var(--txt);
}

main {
  padding-top: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  text-align: center;
}

#desc {
  padding-bottom: 0.2rem;
}

.divider {
  border-top: 0.1rem solid var(--border);
}

.border-top {
  border-top: 1px solid var(--border);
}

.border-bottom {
  border-bottom: 1px solid var(--border);
}

.btn-link {
  padding-left: 0;
}

.author {
  padding-top: 1rem;
}

.footer {
  padding-bottom: 2rem;
}

.mr-4 {
  margin-right: 0.8rem;
}

.footer .form-switch {
  margin-top: 0;
  padding-top: 0;
  padding-right: 0.1rem;
}
.footer .form-switch .form-icon {
  top: 0.2em;
}
/* .footer .form-checkbox input:checked + .form-icon,
.form-radio input:checked + .form-icon,
.form-switch input:checked + .form-icon {
  background: var(--wilsweblue);
  border-color: var(--wilsweblue);
} */

/* Måste vara längst ned på sidan */
[data-theme='dark'] {
  --txt: #f4f4f4;
  --txt-hover: #c3b7b7;
  --bg1: #201e1e;
  --bg2: #131111;
  --border: hsl(0, 3%, 18%);
  --border-vert: hsl(0, 0%, 23%);
}
